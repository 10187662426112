import { useSelector } from 'react-redux';
import { useEffect,useRef,useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';
import { AppState } from './reducers';

const Content = () => {
    const { username, token } = useSelector<AppState, any>((state) => state.authentication);
    const unsavedExamIds = useSelector<AppState, any>((state) => state.examinations.unsavedExamIds);

    function getExams() {
        window.onclose = () => {
            alert("herre");
        }

        window.onbeforeunload = (evt: any) => {
            if (!unsavedExamIds|| unsavedExamIds.length < 1) 
                    return ;                
            return "Do you want to leave the page?";
        }
        window.onunload = (ev: any) => {
            if (unsavedExamIds && unsavedExamIds.length > 0) {
                const formData = new FormData();
                formData.append('medicalExamIds', unsavedExamIds.join(','));
                formData.append('username', username);
                navigator.sendBeacon("/api/examinations/unlocking", formData);
            }
        }
    }

    useEffect(() => {
        return () => {
            window.onbeforeunload = null;
            window.onunload = null;
            window.onclose = null;
        }
    }, [])

    useEffect(() => {
        getExams();
    }, [unsavedExamIds, token])

    return (
        <SideNavBarLayout title="Dictation">
            <Switch>
                {routes.map(({ path, component }) => (
                    <Route
                        exact
                        key={path}
                        path={path}
                        component={component}
                    />
                ))}
                <Redirect to="/home" />
            </Switch>
        </SideNavBarLayout>
    );
}

export default Content;
