import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import './popup-modals.scss';

const ConfirmationModal = (props: any) => {
    const {
        showPopup,confirmButtonText,denyButtonText, text, title, onDeny, onConfirm,
    } = props;

    return (
        <Popup
            visible={showPopup}
            onHiding={onDeny}
            dragEnabled={false}
            closeOnOutsideClick={false}
            showCloseButton
            showTitle
            title={title}
            container=".dx-viewport"
            width={400}
            height={220}
        >
            <p>
                {text}
            </p>
            <div className="buttons">
                <Button className="selection_btnl" text={confirmButtonText} onClick={() => onConfirm()} />
                <Button className="selection_btnr" text={denyButtonText} onClick={() => onDeny(false)} />
            </div>
        </Popup>
    );
};

export default React.memo(ConfirmationModal);
