import { ThunkAction } from 'redux-thunk';
import Axios from 'axios';
import notify from 'devextreme/ui/notify';
import * as constants from '../utils/constants';
import IExamLocked from '../interfaces/examinations/IExamLocked';
import IDictationJob from '../interfaces/examinations/IDictationJob';
import { AppState } from '../reducers';

import {
    COMPOSE_EXAMINATION_FAILED,
    COMPOSE_EXAMINATION_STARTED,
    COMPOSE_EXAMINATION_SUCCESSFUL,
    ExaminationsActionTypes,
    GET_EXAMINATIONS_FAILED,
    GET_EXAMINATIONS_STARTED,
    GET_EXAMINATIONS_SUCCESSFUL,
    LOCK_EXAMINATION_FAILED,
    LOCK_EXAMINATION_STARTED,
    LOCK_EXAMINATION_SUCCESSFUL,
    SET_EXAMINATION_FILTER,
    SIGN_EXAMINATION_FAILED,
    SIGN_EXAMINATION_STARTED,
    SIGN_EXAMINATION_SUCCESSFUL,
    EDIT_EXAM_FIELDS,
    CLEAR_FILTERS,
    GET_ADDITIONAL_DETAILS_STARTED,
    GET_ADDITIONAL_DETAILS_SUCCESSFUL,
    GET_ADDITIONAL_DETAILS_FAILED,
    SELECT_EXAMINATION,
    OPEN_EXAM_IN_EDITOR,
    SET_UNSAVED_RESULT,
    CANCEL_EXAMINATION_STARTED,
    CANCEL_EXAMINATION_SUCCESSFUL,
    CANCEL_EXAMINATION_FAILED,
} from '../actionTypes/examinationsActionTypes';
import { authorizedRequest } from '../utils/authorizedRequest';

export const getExaminationsStarted = (): ExaminationsActionTypes => ({
    type: GET_EXAMINATIONS_STARTED,
});

export const getExaminationsFailed = (error: Error): ExaminationsActionTypes => ({
    type: GET_EXAMINATIONS_FAILED,
    error,
});

export const getExaminationsSuccessful = (data: any[], skip: number, take: number, totalNumber: number): ExaminationsActionTypes => ({
    type: GET_EXAMINATIONS_SUCCESSFUL,
    data,
    skip,
    take,
    totalNumber,
});

export const setFilter = (fieldName: string, fieldValue: any): ExaminationsActionTypes => ({
    type: SET_EXAMINATION_FILTER,
    fieldName,
    fieldValue,
});

export const clearFilters = () => ({
    type: CLEAR_FILTERS,
});

export const saveUnsigendExam = (id: any, rtfString: string) => ({
    type: SET_UNSAVED_RESULT,
    rtfString,
    medicalExamId: id,
})

export function getExaminations(skip: number, take: number): ThunkAction<Promise<any>, AppState, null, any> {
    return async function (dispatch: any, useState) {

        dispatch(getExaminationsStarted());

        const {
            dateRange: { startDate, stopDate }, medicalFacilities, includeNoResult, equipment, fieldFilter: { fieldName, filterValue },
        } = useState().examinations.filters;

        try {
            const response = authorizedRequest.get('/api/examinations', {
                params: {
                    skip,
                    take: 100000,
                    medicalFacilities: medicalFacilities.join(','),
                    equipment: equipment.join(','),
                    searchCriteria: fieldName,
                    searchValue: filterValue,
                    orderby: '',
                    startDate,
                    stopDate,
                    includeNoResult,
                },
            });

            return dispatch(getExaminationsSuccessful((await response).data, skip, take, 1000));
        } catch (error: any) {
            return dispatch(getExaminationsFailed(error));
        }
    };
}

export function getResult(pdfPath: string): Promise<any> {
    return authorizedRequest.get('api/examinations/getPdf', { params: { pdfFilePath: pdfPath }, responseType: 'arraybuffer' })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;

            let fileName = pdfPath.split('\\').pop();
            if (fileName && fileName.includes('.rtf')) {
                fileName = fileName.replace('.rtf', '.pdf');
            }
            link.setAttribute('download', fileName || 'Result.pdf');
            document.body.appendChild(link);
            link.click();
        }).catch((err: any) => {
            if (err.response?.status === 404) {
                notify({ message: 'Fișierul PDF nu a fost găsit.', width: 300 }, 'error', 2000);
            } else {
                notify({ message: 'A apărut o eroare în timpul aducerii PDF-ului.' }, 'error', 2000);
            }
        });
}
//===========================================================================================//

export const LockExaminationStarted = (): ExaminationsActionTypes => ({
    type: LOCK_EXAMINATION_STARTED,
});

export const LockExaminationSuccessful = (): ExaminationsActionTypes => ({
    type: LOCK_EXAMINATION_SUCCESSFUL,
});

export const LockExaminationFailed = (): ExaminationsActionTypes => ({
    type: LOCK_EXAMINATION_FAILED,
});

export function lockExamination(lockedExam: IExamLocked, successCallBack: Function, failedCallback: Function): Promise<any> {
    return authorizedRequest.post('/api/examinations/locking', lockedExam).then((e) => {
        if (e.data) {
            switch (e.data) {
                case constants.SUCCESS:
                    successCallBack();
                    break;
                case constants.FORBIDDEN:
                    failedCallback("FORRBIDEN");
                    break;
                case constants.PENDING:
                    failedCallback();
                    notify('Examinarea este in curs de procesare, reîncărcati', 'error', 3000);
                    break;
                case constants.LOCKED:
                    failedCallback();
                    notify('Examinarea a fost blocata de alt utilizator, reîncărcati', 'error', 3000);
                    break;
                default:
            }
        }
    });
}

export function lockMultipleExaminations(lockedExams: IExamLocked[], successCallBack: Function, failedCallback: Function): Promise<any> {
    return authorizedRequest.post('/api/examinations/lock-multiple', lockedExams).then((e) => {
        if (e.data) {
            switch (e.data) {
                case constants.FORBIDDEN:
                    failedCallback("FORRBIDEN");
                    break;
                case constants.CHANGED:
                    failedCallback("CHANGED");
                    break;
                case constants.SUCCESS:
                    successCallBack();
                    break;
                case constants.PENDING:
                    failedCallback();
                    notify('Examinarea este in curs de procesare, reîncarcati', 'error', 3000);
                    break;
                case constants.LOCKED:
                    failedCallback();
                    notify('Examinarea a fost blocata de alt utilizator, reîncarcati', 'error', 3000);
                    break;
                default:
            }
        }
    });
}

// ===========================================================================================//

export const SignExaminationStarted = (): ExaminationsActionTypes => ({
    type: SIGN_EXAMINATION_STARTED,
});

export const SignExaminationSuccessful = (medicalExamId: any): ExaminationsActionTypes => ({
    type: SIGN_EXAMINATION_SUCCESSFUL,
    medicalExamId,
});

export const SignExaminationFailed = (): ExaminationsActionTypes => ({
    type: SIGN_EXAMINATION_FAILED,
});

export function signExamination(resultFile: File, dictationJob: IDictationJob, patientId: string, succesCallback: Function)
    : ThunkAction<Promise<any>, AppState, null, any> {
    return function (dispatch: any) {
        dispatch(SignExaminationStarted());

        const formData = new FormData();
        formData.append('rtfFile', resultFile);
        formData.append('dictationJob', JSON.stringify(dictationJob));
        formData.append('patientId', patientId);

        return authorizedRequest.post('/api/dictationJobs', formData)
            .then(() => {
                notify('Examinarea a fost semnata cu succes.', 'success', 3000);
                succesCallback();
                dispatch(SignExaminationSuccessful(dictationJob.medicalExamId));
            })
            .catch((error: Error) => {
                notify('A aparut o eroare si examinarea nu a fost semnata', 'error', 3000);
                dispatch(getExaminationsFailed(error));
            });
    };
}

// ===========================================================================================//

export const ComposeExaminationStarted = (): ExaminationsActionTypes => ({
    type: COMPOSE_EXAMINATION_STARTED,
});

export const ComposeExaminationSuccessful = (): ExaminationsActionTypes => ({
    type: COMPOSE_EXAMINATION_SUCCESSFUL,
});

export const ComposeExaminationFailed = (): ExaminationsActionTypes => ({
    type: COMPOSE_EXAMINATION_FAILED,
});

// ===========================================================================================//

export const GetAdditionalDetailsStarted = (): ExaminationsActionTypes => ({
    type: GET_ADDITIONAL_DETAILS_STARTED,
});

export const GetAdditionalDetailsSuccessful = (data: any): ExaminationsActionTypes => ({
    type: GET_ADDITIONAL_DETAILS_SUCCESSFUL,
    details: data,
});

export const GetAdditionalDetailsFailed = (error: Error): ExaminationsActionTypes => ({
    type: GET_ADDITIONAL_DETAILS_FAILED,
    error,
});

export function getExamDetails(medicalExamId: string, currentResultFilePath: string): ThunkAction<Promise<any>, AppState, null, any> {
    return async function (dispatch: any) {
        dispatch(GetAdditionalDetailsStarted);

        try {
            const response = await authorizedRequest.get('api/examinations/details', { params: { medicalExamId, currentResultFilePath } });
            return dispatch(GetAdditionalDetailsSuccessful(response.data));
        } catch (error: any) {
            return dispatch(GetAdditionalDetailsFailed(error.response?.data));
        }
    };
}

// ===========================================================================================//

export const editEditable = (fieldName: string, fieldValue: string): ExaminationsActionTypes => ({
    type: EDIT_EXAM_FIELDS,
    fieldName,
    fieldValue,
});

export const selectExamiantionAction = (selected: any): ExaminationsActionTypes => ({
    type: SELECT_EXAMINATION,
    selected,
})

export const openExamInEditor = (examination: any): ExaminationsActionTypes => ({
    type: OPEN_EXAM_IN_EDITOR,
    examination,
})

// ===========================================================================================//

export function cancelExamination(medicalExamId: string, succesCallback:Function): ThunkAction<Promise<any>, AppState, null, any> {
    return async function (dispatch: any) {
        dispatch(cancelExamStarted());

        try {
            const response = await authorizedRequest.delete('api/examinations/'+ medicalExamId);
            console.log(response);
            succesCallback();
            
            return dispatch(cancelExamSuccessful(medicalExamId));
        } catch (error: any) {
            return dispatch(cancelExamFailed());
        }
    };
}

export const cancelExamStarted = () => ({
    type:  CANCEL_EXAMINATION_STARTED,
})

export const cancelExamSuccessful = (medicalExamId:string)=>({
    type:  CANCEL_EXAMINATION_SUCCESSFUL,
    medicalExamId
})

export const cancelExamFailed = () =>({
    type:  CANCEL_EXAMINATION_FAILED,
})