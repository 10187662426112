
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import AuthenticationActionTypes, * as authenticationActionTypes from '../actionTypes/authenticationActionTypes';
import { saveAuthenticationState, saveUserConfig } from '../utils/authenticationStateLoader';
import { sessionStore } from '../utils/storageWrapper';

export const loginStarted = (): AuthenticationActionTypes => ({
    type: authenticationActionTypes.LOGIN_STARTED,
});

export const loginSuccessful = (username: string, token: string, refreshToken: string) => ({
    type: authenticationActionTypes.LOGIN_SUCCESSFUL,
    username,
    token,
    refreshToken,
});

export const loginFailed = (error: Error): AuthenticationActionTypes => ({
    type: authenticationActionTypes.LOGIN_FAILED,
    error,
});

export function login(username: string, password: string) {
    return async (dispatch: any) => {
        try {
            dispatch(loginStarted());
            const user = await axios.post('api/authentication/login ', { username, password });
            const medicalFacilities = await axios.get('api/authentication/medical-facilities');
            const equipment = await axios.get('api/authentication/equipment');

            dispatch(getMedicalFacilitiesSuccesfull(medicalFacilities.data));
            dispatch(getEquipmentSuccesfull(equipment.data));
            
            const{loginData, userConfig} = user.data;
            dispatch(loginSuccessful(loginData.username, loginData.token, loginData.refreshToken));
            dispatch(getUserConfigSuccesfull(userConfig));
            
            saveAuthenticationState({ username, token: loginData.jwtToken, refreshToken: loginData.refreshToken });
            saveUserConfig(userConfig);

          return dispatch(loginSuccessful(loginData.userName, loginData.jwtToken, loginData.refreshToken));

        } catch (error: any) {
            dispatch(loginFailed(error));
            notify("Utilizator sau parolă greșite." ,'error', 2000);
        }
    }
}

export function getmedicalFacilities(){
    return function (dispatch: any) {
        return axios.get('api/authentication/medical-facilities')
            .then((response) => {
                dispatch(getMedicalFacilitiesSuccesfull(response.data));
            })
            .catch((error) => {              
            });
    }
}

export function getEQuipment(){
    return function (dispatch: any) {
        return axios.get('api/authentication/equipment')
            .then((response) => {
                dispatch(getEquipmentSuccesfull(response.data));
            })
            .catch((error) => {
              
            });
    }
}

export function logout() {
    return function (dispatch: any) {
        saveAuthenticationState(null);
        saveUserConfig(null);
        sessionStore.clear();
        return dispatch(logoutSuccessful());
    }
}

export const logoutStarted = (): AuthenticationActionTypes => ({
    type: authenticationActionTypes.LOGOUT_STARTED
});

export const logoutSuccessful = (): AuthenticationActionTypes => ({
    type: authenticationActionTypes.LOGOUT_SUCCESSFUL
});

export const logoutFailed = (error: Error): AuthenticationActionTypes => ({
    type: authenticationActionTypes.LOGOUT_FAILED,
    error: error
});


export function refresh() {
    return function (dispatch: any) {
        dispatch(loginStarted());
        return axios.post('api/authentication/refreshToken')
            .then((response) => {
                saveAuthenticationState({ username:response.data.userName, token: response.data.jwtToken, refreshToken: response.data.refreshToken });
                return dispatch(loginSuccessful(response.data.userName, response.data.jwtToken, response.data.refreshToken));
            })
            .catch((error) => {
                return dispatch(logout())
            });
    }
}

const getMedicalFacilitiesSuccesfull = (medicalFacilities: any[]): AuthenticationActionTypes => ({
    type: authenticationActionTypes.GET_MEDICAL_FACILITIES_SUCCESSFUL,
    medicalFacilities,
});

const getEquipmentSuccesfull = (equipment: any[]): AuthenticationActionTypes => ({
    type: authenticationActionTypes.GET_EQUIPMENT_SUCCESSFUL,
    equipment,
});

const getUserConfigSuccesfull = (userConfig: any): AuthenticationActionTypes => ({
    type: authenticationActionTypes.GET_USER_CONFIG_SUCCESSFUL,
    userConfig,
});


export const queueRequest = (request: Function): AuthenticationActionTypes => ({
    type: authenticationActionTypes.QUEUE_REQUEST,
    request: request
});

export const clearRequestQueue = (): AuthenticationActionTypes => ({
    type: authenticationActionTypes.CLEAR_REQUEST_QUEUE
});
