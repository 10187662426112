import React, {
    useEffect, useRef, useCallback, useMemo,
} from 'react';
import TreeView from 'devextreme-react/tree-view';
import * as events from 'devextreme/events';
import navigation from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './side-navigation-menu.scss';

const SideNavigationPanel = (props) => {
    const {
        children, selectedItemChanged, openMenu, compactMode, onMenuReady,
    } = props;

    const { isLarge } = useScreenSize();
    function normalizePath() {
        return navigation.map((item) => {
            const itemCopy = { ...item };
            if (item.path && !/^\//.test(item.path)) {
                itemCopy.path = `/${item.path}`;
            }
            return { ...itemCopy, expanded: isLarge };
        });
    }

    const items = useMemo(normalizePath, []);

    const {
        navigationData: { currentPath },
    } = useNavigation();

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback(
        (element) => {
            const prevElement = wrapperRef.current;
            if (prevElement) {
                events.off(prevElement, 'dxclick');
            }

            wrapperRef.current = element;
            events.on(element, 'dxclick', (e) => {
                openMenu(e);
            });
        },
        [openMenu],
    );

    useEffect(() => {
        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode]);

    return (
        <div
            className="dx-swatch-additional side-navigation-menu"
            ref={getWrapperRef}
        >
            {children}
            <div className="menu-container">
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr="path"
                    selectionMode="single"
                    focusStateEnabled={false}
                    expandEvent="click"
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width="100%"
                />
            </div>
        </div>
    );
}

export default SideNavigationPanel;