
import React, { useState } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import notify from 'devextreme/ui/notify';
import InformativePopup from '../../components/popup-modals/informative-popup';
import ConfirmationPopup from '../../components/popup-modals/confirmation-popup';
import { AppState } from '../../reducers';
import IExamLocked from '../../interfaces/examinations/IExamLocked';
import {
    lockExamination, lockMultipleExaminations, selectExamiantionAction, openExamInEditor
} from '../../actions/examinationsActions';
import RadiologistList from './radiologistList';

const Higherlevel = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [isVisibleInfo, showInfoPopup] = useState('');
    const [isVisibleConfirm, showConfirmPopup] = useState(false);

    const aaa: { [className: string]: { title: string, message: string } } = {
        "LOCKED": {
            "title": "Stare invalidă",
            "message": "Studiul pe care l-ați selectat pentru dictare nu este in starea C/CE sau F."
        },
        "FORBIDDEN": {
            "title": "Editare nepermisă",
            "message": "Această examinare este semnată de către un alt utilizator și nu poate să fie modificată."
        },
        "CHANGED": {
            "title": "Examinare modificată",
            "message": "Această examinare a fost modificată.Vă rugăm reîncărcați examinările."
        }
    }

    const loading = useSelector<AppState, any>((state) => state.examinations.loading);
    const openedInEditor = useSelector<AppState, any>((state) => state.examinations.openedInEditor);
    const selected = useSelector<AppState, any>((state) => state.examinations.selected);

    const openEditorWithSelected = () => {

        if (selected.subProcedures) {

            const exams = selected.subProcedures.map((e: any) => ({
                MedicalExamId: e.medicalExamId,
                LockUsername: '',
                LockTime: new Date(),
                ExaminationStatus: e.status,

            }));
            lockMultipleExaminations(exams, () => {
                if (isVisibleConfirm) {
                    showConfirmPopup(false);
                }
                dispatch(openExamInEditor(selected));
                history.push('./results');
            }, (aaa: string) => { showInfoPopup("CHANGED"); showConfirmPopup(false) });
            return;
        }

        const examLocked: IExamLocked = {
            LockUsername: 'pxdrad',
            MedicalExamId: selected.medicalExamId,
            LockTime: new Date(),
            ExamintionStatus: selected.status
        };

        lockExamination(examLocked, () => {
            if (isVisibleConfirm) {
                showConfirmPopup(false);
            }
            dispatch(openExamInEditor(selected));
            history.push('./results');
        }, (saas: string) => { showInfoPopup('FORBIDDEN'); showConfirmPopup(false) });
    };

    const validateSelection = () => {

        if (!selected || !selected.status) {
            notify('Nici un studiu selectat.', 'warning', 2000);
        } else if (selected.status !== 'F' && selected.status !== 'C' && selected.status !== 'CE') {
            showInfoPopup('LOCKED');
        } else if (openedInEditor.medicalExamId && (selected.medicalExamId !== openedInEditor.medicalExamId)) {
            showConfirmPopup(true);
        } else {
            openEditorWithSelected();
        }
    };

    const closeInfo = () => {
        showInfoPopup('');
    };

    const onDeny = () => {
        showConfirmPopup(false);
    };

    return (
        <>
            <NotRerenderComponent validateSelection={validateSelection} />
            <InformativePopup showPopup={isVisibleInfo} hidePopup={closeInfo} title={isVisibleInfo ? aaa[isVisibleInfo].title : ""} text={isVisibleInfo ? aaa[isVisibleInfo].message : ""} />

            {/* <LoadPanel activeStateEnabled visible={loading} /> */}
            <ConfirmationPopup
                showPopup={isVisibleConfirm}
                confirmButtonText="Da"
                denyButtonText="Nu"
                onDeny={() => onDeny()}
                onConfirm={openEditorWithSelected}
                title="Părăsire studiu curent"
                text="Sunteți pe cale să părăsiți studiul curent. Doriți să continuați?"
            />
        </>
    );
};

class NotRerenderComponent extends React.Component<any, any> {
    shouldComponentUpdate(prevprops: any) {
        return false;
    }

    render() {
        return <RadiologistList validateSelection={() => this.props.validateSelection()} />;
    }
}
export default Higherlevel;
