export const GET_EXAMINATIONS_STARTED = 'GET_EXAMINATIONS_STARTED';
export const GET_EXAMINATIONS_SUCCESSFUL = 'GET_EXAMINATIONS_SUCCESSFUL';
export const GET_EXAMINATIONS_FAILED = 'GET_EXAMINATIONS_FAILED';
export const SET_EXAMINATION_FILTER = 'SET_EXAMINATION_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const EDIT_EXAM_FIELDS = 'EDIT_EXAM_FIELDS';
export const LOCK_EXAMINATION_STARTED = 'LOCK_EXAMINATION_STARTED';
export const LOCK_EXAMINATION_SUCCESSFUL = 'LOCK_EXAMINATION_SUCCESSFUL';
export const LOCK_EXAMINATION_FAILED = 'LOCK_EXAMINATION_FAILED';
export const SIGN_EXAMINATION_STARTED = 'SIGN_EXAMINATION_STARTED';
export const SIGN_EXAMINATION_SUCCESSFUL = 'SIGN_EXAMINATION_SUCCESSFUL';
export const SIGN_EXAMINATION_FAILED = 'SIGN_EXAMINATION_FAILED';
export const COMPOSE_EXAMINATION_STARTED = 'COMPOSE_EXAMINATION_STARTED';
export const COMPOSE_EXAMINATION_SUCCESSFUL = 'COMPOSE_EXAMINATION_SUCCESSFUL';
export const COMPOSE_EXAMINATION_FAILED = 'COMPOSE_EXAMINATION_FAILED';
export const GET_ADDITIONAL_DETAILS_STARTED = 'GET_ADDITIONAL_DETAILS_STARTED';
export const GET_ADDITIONAL_DETAILS_SUCCESSFUL = 'GET_ADDITIONAL_DETAILS_SUCCESSFUL';
export const GET_ADDITIONAL_DETAILS_FAILED = 'GET_ADDITIONAL_DETAILS_FAILED';
export const SELECT_EXAMINATION = 'SELECT_EXAMINATION';
export const OPEN_EXAM_IN_EDITOR = 'OPEN_EXAM_IN_EDITOR';
export const SET_UNSAVED_RESULT = 'SET_UNSAVED_RESULT';
export const CANCEL_EXAMINATION_STARTED = 'CANCEL_EXAMINATION_STARTED';
export const CANCEL_EXAMINATION_SUCCESSFUL = 'CANCEL_EXAMINATION_SUCCESSFUL';
export const CANCEL_EXAMINATION_FAILED = 'CANCEL_EXAMINATION_FAILED';


interface GetExaminationsStarted {
    type: typeof GET_EXAMINATIONS_STARTED
}

interface SelectExamination {
    type: typeof SELECT_EXAMINATION,
    selected: any
}

interface OpenExaminationInEditor {
    type: typeof OPEN_EXAM_IN_EDITOR,
    examination: any
}

interface GetExaminationsSuccessful {
    type: typeof GET_EXAMINATIONS_SUCCESSFUL,
    data: any[],
    skip: number,
    take: number,
    totalNumber: number,
}
interface GetExaminationsFailed {
    type: typeof GET_EXAMINATIONS_FAILED,
    error: Error
}
interface SetExaminationFilter {
    type: typeof SET_EXAMINATION_FILTER,
    fieldName: string,
    fieldValue: any
}

interface ClearFilters {
    type: typeof CLEAR_FILTERS
}

interface LockExaminationStarted {
    type: typeof LOCK_EXAMINATION_STARTED
}

interface LockExaminationSuccessful {
    type: typeof LOCK_EXAMINATION_SUCCESSFUL
}
interface LockExaminationFailed {
    type: typeof LOCK_EXAMINATION_FAILED
}

interface SignExaminationStarted {
    type: typeof SIGN_EXAMINATION_STARTED
}
interface SignExaminationSuccessful {
    type: typeof SIGN_EXAMINATION_SUCCESSFUL,
    medicalExamId:any
}
interface SignExaminationFailed {
    type: typeof SIGN_EXAMINATION_FAILED
}

interface ComposeExaminationStarted {
    type: typeof COMPOSE_EXAMINATION_STARTED
}
interface ComposeExaminationSuccessful {
    type: typeof COMPOSE_EXAMINATION_SUCCESSFUL
}
interface ComposeExaminationFailed {
    type: typeof COMPOSE_EXAMINATION_FAILED
}

interface GetAdditionalDetailsStarted {
    type: typeof GET_ADDITIONAL_DETAILS_STARTED,
}

interface GetAdditionalDetailsSuccessful {
    type: typeof GET_ADDITIONAL_DETAILS_SUCCESSFUL,
    details: any
}

interface SetUnsavedRsult {
    type: typeof SET_UNSAVED_RESULT,
    medicalExamId: any,
    rtfString: string
}
interface GetAdditionalDetailsFailed {
    type: typeof GET_ADDITIONAL_DETAILS_FAILED,
    error: Error
}
interface EditExamFields {
    type: typeof EDIT_EXAM_FIELDS,
    fieldName: string,
    fieldValue: string
}

interface CancelExaminationStarted{
    type: typeof CANCEL_EXAMINATION_STARTED
}
interface CancelExaminationSuccessful{
    type: typeof CANCEL_EXAMINATION_SUCCESSFUL
    medicalExamId: any
}
interface CancelExaminationFailed{
    type: typeof CANCEL_EXAMINATION_FAILED
}

export type ExaminationsActionTypes = GetExaminationsStarted | EditExamFields |
    GetExaminationsSuccessful | GetExaminationsFailed | SetExaminationFilter |
    LockExaminationStarted | LockExaminationSuccessful | LockExaminationFailed |
    SignExaminationStarted | SignExaminationSuccessful | SignExaminationFailed |
    GetAdditionalDetailsStarted | GetAdditionalDetailsSuccessful | GetAdditionalDetailsFailed |
    ComposeExaminationStarted | ComposeExaminationSuccessful | ComposeExaminationFailed | ClearFilters |
    SelectExamination | OpenExaminationInEditor | SetUnsavedRsult|CancelExaminationStarted|CancelExaminationSuccessful|CancelExaminationFailed;
