import React from 'react';
import { Template } from 'devextreme-react/core/template';
import Toolbar, { Item } from 'devextreme-react/toolbar';
// import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import ThemeSwitch from './themeSwitch';

const Header = (props: {
    children?: any;
    menuToggleEnabled?: any;
    title?: any;
    toggleMenu?: any;
}) => {
    const { title } = props;

    return (
        <header className="header-component">
            <Toolbar className="header-toolbar content-header">

                {props.children}
                <Item
                    location="before"
                    cssClass="header-title"
                    text={title}
                    visible={!!title}
                />
                <Item
                    location="after"
                    locateInMenu="auto"
                    menuItemTemplate=""
                >
                    <ThemeSwitch />
                </Item>
                <Item
                    location="after"
                    locateInMenu="auto"
                    menuItemTemplate="userPanelTemplate"
                >
                    <UserPanel menuMode="context" />
                </Item>
                <Template name="userPanelTemplate">
                    <UserPanel menuMode="list" />
                </Template>
            </Toolbar>
        </header>
    );
};
export default Header;
