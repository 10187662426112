import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { DateBox } from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import TagBox from 'devextreme-react/tag-box';
import { TextBox } from 'devextreme-react/text-box';
import { AppState } from '../../reducers';
import { setFilter, getExaminations, clearFilters } from '../../actions/examinationsActions';
import { getmedicalFacilities,getEQuipment } from '../../actions/authenticationActions';
import { loadMessages} from 'devextreme/localization';

const searchCriteria = ['Nr. Examinare', 'Nume Pacient', 'ID Pacient', 'CNP Pacient', 'Nume Medic', 'Nume Procedura'];
loadMessages({
    "en": {
        "dxList-selectAll": "Toate",
    }
});

const FiltersBand = (props: any) => {
    
    const dispatch = useDispatch();
    const filters = useSelector<AppState, any>((state) => state.examinations.filters);
    const medicalFacilities = useSelector<AppState, any>((state) => state.examinations.medicalFacilities);
    const equipment = useSelector<AppState, any>((state) => state.examinations.eqauipment);
    
    useEffect(() => {
      if(!medicalFacilities.length){
          dispatch(getmedicalFacilities())
        }
      if(!equipment.length){
        dispatch(getEQuipment());
      }
    }, []);
   
    return (
        <div className="content-block filterss">
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">Unități medicale</div>
                    <div className="dx-field-value">
                        <TagBox
                            items={medicalFacilities}
                            showSelectionControls
                            placeholder="Selectați..."
                            noDataText='Nu există unități medicale.'
                            maxDisplayedTags={0}
                            hint="Selectați unitățile medicale"
                            value={filters.medicalFacilities}
                            onValueChanged={(e) => dispatch(setFilter('medicalFacilities', e.value))}
                            showDropDownButton
                            stylingMode="outlined"
                            displayExpr="facilityName"
                            valueExpr="medicalFacilityId"
                            selectAllMode="page"
                        />
                    </div>
                </div>
            </span>
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">Echipamente</div>
                    <div className="dx-field-value">
                        <TagBox
                            items={equipment}
                            className="aicia"
                            showClearButton={false}
                            value={filters.equipment}
                            showSelectionControls
                            hint='Selectați echipamentele'
                            noDataText='Nu există echipamente.'
                            onValueChanged={(e) => dispatch(setFilter('equipment', e.value))}
                            placeholder="Selectați..."
                            maxDisplayedTags={1}
                            multiline={false}
                            showDropDownButton
                            stylingMode="outlined"
                            displayExpr="prodedureGroupCode"
                            valueExpr="prodedureGroupCode"
                            selectAllMode="page"
                        />
                    </div>
                </div>
            </span>
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">Criteriu de căutare</div>
                    <div className="dx-field-value">
                        <SelectBox
                            items={searchCriteria}
                            value={filters.fieldFilter?.fieldName ? filters.fieldFilter.fieldName : searchCriteria[0]}
                            stylingMode="outlined"
                            onValueChanged={(e) => dispatch(setFilter('fieldFilter', { ...filters.fieldFilter, fieldName: e.value }))}
                            defaultValue="Nr. Examinare"
                        />
                    </div>
                </div>
            </span>
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">Valoare</div>
                    <div className="dx-field-value">
                        <TextBox
                            stylingMode="outlined"
                            hint={filters.fieldFilter?.filterValue}
                            value={filters.fieldFilter?.filterValue}
                            onEnterKey={() => { props.clearSelection(); dispatch(getExaminations( 0, 100)); }}
                            onValueChanged={(e) => dispatch(setFilter('fieldFilter', { ...filters.fieldFilter, filterValue: e.value }))}
                            placeholder="Valoare..."
                            showClearButton
                        />
                    </div>
                </div>
            </span>
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">De la</div>
                    <div className="dx-field-value">
                        <DateBox
                            defaultValue={null}
                            type="date"
                            onEnterKey={() => { props.clearSelection(); dispatch(getExaminations(0,100)); }}
                            value={filters.dateRange.startDate}
                            onValueChanged={(e) => dispatch(setFilter('dateRange', { ...filters.dateRange, startDate: e.value }))}
                            stylingMode="outlined"
                            displayFormat="dd-MM-yyyy"
                            placeholder="zz/ll/aaaa"
                            showClearButton
                        />
                    </div>
                </div>
            </span>
            <span>
                <div className="dx-field medical-drop-dw">
                    <div className="dx-field-label">Până la</div>
                    <div className="dx-field-value">
                        <DateBox
                            defaultValue={Date.now()}
                            value={filters.dateRange.stopDate}
                            onValueChanged={(e) => {
                                const endDate = e.value;

                                if (endDate?.getMonth()) {
                                    endDate.setHours(23, 59, 59);
                                    dispatch(setFilter('dateRange', { ...filters.dateRange, stopDate: endDate }))
                                    return;
                                }
                                dispatch(setFilter('dateRange', { ...filters.dateRange, stopDate: null }))
                            }}
                            type="date"
                            stylingMode="outlined"
                            placeholder="zz/ll/aaaa"
                            displayFormat="dd-MM-yyyy"
                            showClearButton
                        />
                    </div>
                </div>
            </span>
            <div className="dx-field medical-drop-dw my-check">
                <div className="dx-field-label">Fără rezultat</div>
                <div className="dx-field-value">
                    <CheckBox
                        defaultValue
                        value={filters.includeNoResult}
                        hoverStateEnabled
                        focusStateEnabled={false}
                        onValueChanged={(e) => dispatch(setFilter('includeNoResult', e.value))}
                    />
                </div>
            </div>
          
                <Button
                    className='filters-button'
                    icon="search"
                    text="Caută"
                    onClick={() => { props.clearSelection(); dispatch(getExaminations(0, 100)); }}
                    type="danger"
                    stylingMode="contained"
                />
                <Button
                   
                    icon="clear"
                    text="Golește"
                    type="danger"
                    onClick={() => dispatch(clearFilters())}
                    stylingMode="contained"
                />
        </div>
    );
};

export default React.memo(FiltersBand);


