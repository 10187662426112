import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import './popup-modals.scss';

const InformativePopup = (props: any) => {
    const {
        showPopup, text, title, hidePopup,
    } = props;
    
    return (
        <Popup
            visible={showPopup}
            onHiding={hidePopup}
            dragEnabled={false}
            closeOnOutsideClick={false}
            showCloseButton
            showTitle
            title={title}
            container=".dx-viewport"
            width={400}
            height={220}
        >
            <p>
                {text}
            </p>
            <Button className="confirmation selection_btnl" text="Continuă" stylingMode="contained" icon="check" onClick={() => hidePopup()} />
        </Popup>
    );
};

export default React.memo(InformativePopup);
