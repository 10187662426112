/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../actions/authenticationActions'
import { useHistory } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import './login-form.scss';

import { ReactComponent as LoginSvg } from '../../assets/Dictation.svg';

const emailEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Utilizator',
    mode: 'text',
    inputAttr: {
        autocomplete: 'new-password',
    },
};

const passwordEditorOptions = {
    stylingMode: 'outlined',
    placeholder: 'Parolă',
    mode: 'password',
    inputAttr: {
        autocomplete: 'new-password',
    },
};

const LoginForm = () => {
    const dispatch = useDispatch();
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const formData = useRef({});

    const onSubmit = (e) => {
        e.preventDefault();
        const { username, password } = formData.current;
        dispatch(login(username, password));
};

return (
    <div className="login-page">
        <form className="login-card" onSubmit={onSubmit}>
            <div className="left-side">
                <LoginSvg />
            </div>
            <div className="right-side">
                <Form formData={formData.current} disabled={loggingIn}>
                    <Item
                        dataField="username"
                        editorType="dxTextBox"
                        editorOptions={emailEditorOptions}
                    >
                        <RequiredRule message="Utilizatorul este obligatoriu" />
                        <Label visible={false} />
                    </Item>
                    <Item
                        dataField="password"
                        editorType="dxTextBox"
                        editorOptions={passwordEditorOptions}
                    >
                        <RequiredRule message="Parola este obligatorie" />
                        <Label visible={false} />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions
                            width="100%"
                            type="default"
                            useSubmitBehavior
                        >
                            <span className="dx-button-text">
                                {
                                    loggingIn
                                        ? <LoadIndicator width="24px" height="24px" visible />
                                        : 'Conectează-te'
                                }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </div>
        </form>
    </div>
);
}
export default LoginForm;