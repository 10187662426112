import { localStore, sessionStore } from './storageWrapper';
import {authorizedRequest} from './authorizedRequest';
import axios from 'axios';

export function loadAuthenticationState() {
    try {
        const state = initializeState();
        let serializedState = localStore.getItem("pxd-authentication-state");
        let serializedUserConfig = localStore.getItem("pxd-user-config");
       
        const currentSubdomain = sessionStore.getItem("loggedInFrom") || "/";

        if (serializedState !== null) {
            const deserializedState = JSON.parse(serializedState);
            const deserializedUserConfig = serializedUserConfig?JSON.parse(serializedUserConfig):{};

            state.token = deserializedState.token;
            state.refreshToken = deserializedState.refreshToken;
            state.username = deserializedState.username;
            state.loggedIn = (deserializedState.refreshToken !== null);
            state.loggedInFrom = currentSubdomain;
            state.userConfig = deserializedUserConfig; 
        };
       
        return { authentication: state };
    }
    catch (err) {
        return { authentication: initializeState() };
    }
}

export function saveAuthenticationState(state: any) {
    try {
        let serializedState = JSON.stringify(state);
 
        localStore.setItem("pxd-authentication-state", serializedState);
        state ? authorizedRequest.defaults.headers.common['Authorization'] = 'Bearer ' + state.token :
            authorizedRequest.defaults.headers.common['Authorization'] = null;
        ;
    }
    catch (err) {
    }
}

export function saveUserConfig(data:any){
    try {
        let serializedState = JSON.stringify(data);
        localStore.setItem("pxd-user-config", serializedState);
    }
    catch(err){

    }
}

export function initializeState() {
    return {
        loggingIn: false,
        loggedIn: false,
        loggedInFrom: "",
        token: "",
        refreshToken: "",
        username: "",
        requestQueue: [],
        userConfig:{}
    }
};