import { useState, useEffect } from 'react';
import useCookie from './utils/cookie-handler';

const useTheme = () => {
    const { setValue, item } = useCookie();
    const [theme, changeTheme] = useState(item.themes);

    useEffect(() => {
        const { themes } = item;
        if (themes) {
            changeTheme(themes);
            document.documentElement.className = themes;
            setValue('themes', themes, { maxAge: 60 * 60 * 24 * 365 });
        } else {
            changeTheme('dark-theme');
            document.documentElement.className = 'dark-theme';
            setValue('themes', 'dark-theme', { maxAge: 60 * 60 * 24 * 365 });
        }
    }, []);

    const toggleTheme = () => {
        if (theme === 'dark-theme') {
            const s = document.documentElement;
            s?.classList.remove('dark-theme');
            s?.classList.add('light-theme');
            document.documentElement.className = 'light-theme';
            changeTheme('light-theme');
            setValue('themes', 'light-theme', { maxAge: 60 * 60 * 24 * 365 });
        } else {
            const s = document.documentElement;
            s?.classList.remove('light-theme');
            s?.classList.add('dark-theme');
            document.documentElement.className = 'dark-theme';
            changeTheme('dark-theme');
            setValue('themes', 'dark-theme', { maxAge: 60 * 60 * 24 * 365 });
        }
    };

    return {
        theme, toggleTheme,
    };
};

export default useTheme;
