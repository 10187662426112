
import Button from 'devextreme-react/button';
import Drawer from 'devextreme-react/drawer';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Template } from 'devextreme-react/core/template';
import { SideNavigationMenu, Footer } from '../../components';
import './side-nav-inner-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import useMenuPatch from '../../utils/patches';

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3,
};

export default function ({ title, children }) {
    const history = useHistory();
    const { isXSmall, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(
        isLarge ? MenuStatus.Closed : MenuStatus.Closed,
    );

    const temporaryOpenMenu = useCallback(() => {
    }, []);

    const onNavigationChanged = useCallback(
        ({ itemData: { path }, event }) => {
            history.push(path);

            if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
                setMenuStatus(MenuStatus.Closed);
                event.stopPropagation();
            }
        },
        [history, menuStatus, isLarge],
    );

    return (
        <div className="side-nav-inner-toolbar">
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position="before"
                openedStateMode={isLarge ? 'overlap' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 90}
                maxSize={90}
                shading={!isLarge}
                opened={menuStatus !== MenuStatus.Closed}
                template="menu"
            >
                <div className="container">
                    <div className="layout-body with-footer">
                        <div className="content">
                            {React.Children.map(children, (item) => item.type !== Footer && item)}
                        </div>
                        <div className="content-block">
                            {React.Children.map(children, (item) => item.type === Footer && item)}
                        </div>
                    </div>
                </div>
                <Template name="menu">
                    <SideNavigationMenu
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                    >
                        <Toolbar id="navigation-header">
                            {/* {!isXSmall && (
                                <Item location="before" cssClass="menu-button">
                                    <Button icon="menu" stylingMode="text" />
                                </Item>
                            )}
                            <Item
                                location="before"
                                cssClass="header-title"
                                text={title}
                            /> */}
                        </Toolbar>
                    </SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}
