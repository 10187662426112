import * as authenticationActionTypes from '../actionTypes/authenticationActionTypes';
import { AuthenticationActionTypes } from '../actionTypes/authenticationActionTypes';
import { initializeState } from '../utils/authenticationStateLoader';

const initialState = initializeState();

export default function reducer(state = initialState, action: AuthenticationActionTypes) {
    switch (action.type) {
        case authenticationActionTypes.LOGOUT_SUCCESSFUL:{
            return {
                ...initialState,
            }
        }
        case authenticationActionTypes.LOGIN_STARTED: {
            return {
                ...state,
                loggingIn: true,
            };
        }
        case authenticationActionTypes.LOGIN_SUCCESSFUL: {
            return {
                ...state,
                loggingIn: false,
                username: action.username,
                token: action.token,
                refreshToken: action.refreshToken,
            };
        }
        case authenticationActionTypes.GET_USER_CONFIG_SUCCESSFUL: {
            return {
                ...state,
                userConfig: action.userConfig,
            }
        }
        case authenticationActionTypes.LOGIN_FAILED: {
            return {
                ...state,
                loggingIn: false,
                error: action.error,
            };
        }
        case authenticationActionTypes.QUEUE_REQUEST: {
            return {
                ...state,
                requestQueue: (state.requestQueue as any).concat([action.request])
            };
        }
        case authenticationActionTypes.CLEAR_REQUEST_QUEUE: {
            return {
                ...state,
                requestQueue: []
            };
        }
        default:
            return state;
    }
}
