import IUser from '../interfaces/users/IUser';
import IMedicalFacility from '../interfaces/users/IMedicalFacility';

export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const GET_FACILITY_STARTED = 'GET_FACILITY_STARTED';
export const GET_MEDICAL_FACILITIES_SUCCESSFUL = 'GET_MEDICAL_FACILITIES_SUCCESSFUL';
export const GET_FACILITY_FAILED = 'GET_FACILITY_FAILED';
export const GET_EQUIPMENT_STARTED = 'GET_EQUIPMENT_STARTED';
export const GET_EQUIPMENT_SUCCESSFUL = 'GET_EQUIPMENT_SUCCESSFUL';
export const GET_USER_CONFIG_SUCCESSFUL = 'GET_USERT_CONFIG_SUCCESSFUL';
export const QUEUE_REQUEST = "QUEUED_REQUEST";
export const CLEAR_REQUEST_QUEUE = "CLEARED_REQUEST_QUEUE";

interface LoginStartedAction {
    type: typeof LOGIN_STARTED
}

export interface LoginSuccessfulAction {
    type: typeof LOGIN_SUCCESSFUL,
    username: string,
    token: string,
    refreshToken: string,
}
interface LoginFailedAction {
    type: typeof LOGIN_FAILED,
    error: Error
}

interface LogoutStartedAction {
    type: typeof LOGOUT_STARTED,
}

export interface LogoutSuccessfullAction {
    type: typeof LOGOUT_SUCCESSFUL
}

export interface LogoutFailedAction {
    type: typeof LOGOUT_FAILED,
    error: Error
}

export interface GetFacilityStartedAction {
    type: typeof GET_FACILITY_STARTED
}

export interface GetFacilitySuccessfulAction {
    type: typeof GET_MEDICAL_FACILITIES_SUCCESSFUL,
    medicalFacilities: IMedicalFacility[]
}

export interface GetEquipmentSuccesfullAction {
    type: typeof GET_EQUIPMENT_SUCCESSFUL,
    equipment: any[]
}

export interface GetUserConfigSuccesfullAction {
    type: typeof GET_USER_CONFIG_SUCCESSFUL,
    userConfig: { [label: string]: string }
}

export interface QueueRequest {
    type: typeof QUEUE_REQUEST,
    request: Function
}

export interface ClearRequestQueue {
    type: typeof CLEAR_REQUEST_QUEUE
}


export type AuthenticationActionTypes = LoginStartedAction | LoginSuccessfulAction | LoginFailedAction | QueueRequest | ClearRequestQueue |
    LogoutStartedAction | LogoutSuccessfullAction | LogoutFailedAction | GetFacilitySuccessfulAction | GetEquipmentSuccesfullAction | GetUserConfigSuccesfullAction;

export default AuthenticationActionTypes;
