export const GET_MACROS_STARTED = 'GET_MACROS_STARTED';
export const GET_MACROS_SUCCESSFUL = 'GET_MACROS_SUCCESSFUL';
export const GET_MACROS_FAILED = 'GET_MACROS_FAILED';
export const GET_MACRO_CONTENT_STARTED = 'GET_MACRO_CONTENT_STARTED';
export const GET_MACRO_CONTENT_SUCCESSFUL = 'GET_MACRO_CONTENT_SUCCESSFUL';
export const GET_MACRO_CONTENT_FAILED = 'GET_MACRO_CONTENT_FAILED';
export const SELECT_MACRO = 'SELECT_MACRO';
export const DONE_INSERTING = 'DONE_INSERTING';
export const CLEAR_MACRO_CONTENT = 'CLEAR_MACRO_CONTENT';

interface GetMacrosStarted {
    type: typeof GET_MACROS_STARTED
}

interface GetMacrosSuccessful {
    type: typeof GET_MACROS_SUCCESSFUL
    macrosList: string[]
}

interface GetMacrosFailed {
    type: typeof GET_MACROS_FAILED,
    error: Error
}

interface GetMacroContentStarted {
    type: typeof GET_MACRO_CONTENT_STARTED
}

interface GetMacroContentSuccessful {
    type: typeof GET_MACRO_CONTENT_SUCCESSFUL
    content: string;
    macroName: string;
}

interface GetMacroContentFailed {
    type: typeof GET_MACRO_CONTENT_FAILED,
    error: Error
}

interface DoneInserting {
    type: typeof DONE_INSERTING
}

interface SelectMacro {
    type: typeof SELECT_MACRO,
    rtfString: string
}

interface ClearMacroContent {
    type: typeof CLEAR_MACRO_CONTENT
}

export type MacrosActionTypes = GetMacrosStarted | GetMacrosSuccessful | GetMacrosFailed |DoneInserting|ClearMacroContent|
    GetMacroContentStarted | GetMacroContentSuccessful | GetMacroContentFailed | SelectMacro ;
