import Form, { GroupItem } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react/text-box';
import IExaminationDetailsProps from '../../interfaces/examinations/IExaminationDetailsProps';
import convertToLocale from '../../utils/dateFormatter';

const ExaminationDetails = (props: IExaminationDetailsProps) => {
    const { currentExamination, editEditable, currentExamination: { examEditable } } = props;
    return (
        <div className="form-container dx-card examination-details">
            <Form
                colCount={4}
                id="form"
            >
                <GroupItem caption="Detalii Pacient">
                    <div className="dx-field">
                        <div className="dx-field-label">Nume Pacient</div>
                        <div className="dx-field-value-static">{currentExamination?.patientFullName}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Sex</div>
                        <div className="dx-field-value-static">{currentExamination?.patientSex}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">CNP Pacient</div>
                        <div className="dx-field-value-static">{currentExamination?.patientSSN}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Data Nașterii</div>
                        <div className="dx-field-value-static">{currentExamination?.patientDoB? convertToLocale(currentExamination.patientDoB,"ro-RO",".",false): ""}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Vârstă</div>
                        <div className="dx-field-value-static">{currentExamination?.patientAge}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">ID Pacient</div>
                        <div className="dx-field-value-static">{currentExamination?.patientID}</div>
                    </div>
                </GroupItem>
                <GroupItem caption="Detalii Sursă">
                    <div className="dx-field">

                        <div className="dx-field-label">Unitatea Medicală</div>
                        <div className="dx-field-value-static">{currentExamination?.medicalFacility}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Contract</div>
                        <div className="dx-field-value-static">{currentExamination?.contract}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Medic Radiolog</div>
                        <div className="dx-field-value-static">{currentExamination?.radiologistName}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Tehnician</div>
                        <div className="dx-field-value-static">{currentExamination?.technicianFullName?.replace('^'," ")}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Medic Solicitant</div>
                        <div className="dx-field-value-static">{currentExamination?.refferringPhysicianName}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Telefon</div>
                        <div className="dx-field-value-static"></div>
                    </div>
                </GroupItem>
                <GroupItem caption="Detalii Examinare">
                    <div className="dx-field">
                        <div className="dx-field-label">Nume Procedură</div>
                        <div className="dx-field-value-static">{currentExamination?.procedureDetail}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Nr. Examinare/Procedură</div>
                        <div className="dx-field-value-static">{`${currentExamination?.orderNumber}/${currentExamination?.procedureAssessionNumber}`}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Data/Ora Procedurii</div>
                        <div className="dx-field-value-static">{(currentExamination?.date ? convertToLocale(currentExamination.date,"ro-RO",".",true) : "")}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Valori Clearence Creatinina: </div>
                        <div className="dx-field-value-static" />
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Starea Procedurii</div>
                        <div className="dx-field-value-static">{currentExamination?.status}</div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">ICD10 la internare</div>
                        <div className="dx-field-value-static">{`${currentExamination?.icD10Code} ${currentExamination?.icD10Description}`}</div>
                    </div>

                </GroupItem>
                <GroupItem cssClass="editable" caption="Editare">
                    <div className="dx-field">
                        <div className="dx-field-label">Motivul Examinării</div>
                        <div className="dx-field-value">
                            <TextBox
                                height={30}
                                onValueChange={(e) => editEditable('examReason', e)}
                                maxLength={100}
                                stylingMode="outlined"
                                defaultValue=""
                                value={examEditable?.examReason || ''}
                            />
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Secvență Dicom</div>
                        <div className="dx-field-value">
                            <TextBox
                                height={30}
                                onValueChange={(e) => editEditable('dicomSequence', e)}
                                stylingMode="outlined"
                                maxLength={100}
                                defaultValue=""
                                value={examEditable?.dicomSequence || ''}
                            />
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Note Tehnician</div>
                        <div className="dx-field-value">
                            <TextBox
                                height={30}
                                disabled
                                onValueChange={(e) => editEditable('technicianNotes', e)}
                                maxLength={100}
                                stylingMode="outlined"
                                defaultValue=""
                                value={examEditable?.technicianNotes || ''}
                            />
                        </div>
                    </div>
                    <div className="dx-field">
                        <div className="dx-field-label">Cuvinte Cheie</div>
                        <div className="dx-field-value">
                            <TextBox
                                height={30}
                                onValueChange={(e) => editEditable('examTags', e)}
                                maxLength={100}
                                stylingMode="outlined"
                                defaultValue=""
                                value={examEditable?.examTags || ''}
                            />
                        </div>
                    </div>
                </GroupItem>
            </Form>
        </div>
    );
};

export default ExaminationDetails;
