
import React from 'react';
import { connect } from 'react-redux';
import 'devexpress-richedit/dist/dx.richedit.css';
import './radiologistList.scss';
import {    createOptions, RichEdit, ViewType, RichEditUnit, DocumentFormat, create, Options,
} from 'devexpress-richedit';
import {selectMacro } from '../../actions/macrosActions';

const mapStateToProps = (state: any) => ({
    selectedMacro: state.macros.selectedMacro,
    content: state.macros.content,
});

const mapDispacthToProsps = (dispatch: any) => ({
    saveLast: (value: string) => dispatch(selectMacro(value)),
});
class ReadonlyEditor extends React.Component<any, any> {
    rich = RichEdit;

    constructor(props: any) {
        super(props);
        this.rich = this.rich.bind(this);
    }

    componentWillUnmount(){
        if (!this.rich.prototype.isDisposed) {
            this.rich.prototype.dispose();
        }
    }

    componentDidMount() {
        this.rich.prototype = create(document.getElementById('readonly-macro')!, this.createEditorSettings());
        this.rich.prototype.documentName = 'aa';
    }

    componentDidUpdate() {
        const {content, selectedMacro, } = this.props;
       
        const textLength= this.rich.prototype.document.subDocuments.main.getText().length;                 

        if(textLength !== 0 && !content){           
            this.rich.prototype.document.deleteText({start:0, length:textLength})
            return;
        }
        if (content) {
            this.rich.prototype.openDocument(content, selectedMacro, DocumentFormat.Rtf);
        }
    }

    createEditorSettings = (): Options => {
        const options = createOptions();

        options.bookmarks!.visibility = false;
        options.events.documentLoaded = (e) => {
            e.document.subDocuments.main.getRtf();
            this.props.saveLast(e.document.subDocuments.main.getRtf());
        };
        options.unit = RichEditUnit.Inch;
        options.contextMenu!.enabled = false;
        options.view!.viewType = ViewType.Simple;
        options.readOnly = true;
        options.confirmOnLosingChanges = { enabled: false };
        options.ribbon.clearTabs();
        options.view!.viewType = ViewType.Simple;
        options.view!.simpleViewSettings!.paddings = {
            left: 15,
            top: 15,
            right: 15,
            bottom: 15,
        };
        return options;
    }

    render() {
        
        return (
            <div id="readonly-macro"> </div>
        );
    }
}
export default connect(mapStateToProps, mapDispacthToProsps)(ReadonlyEditor);
