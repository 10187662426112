import React, { useEffect } from 'react';
import './themes/generated/variables.base.scss';
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { Provider, useSelector } from 'react-redux';
import UnauthenticatedContent from './UnauthenticatedContent';
import { NavigationProvider } from './contexts/navigation';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import store from './reducers/store';
import { AppState } from './reducers';

const App = () => {
    const { username, token } = useSelector<AppState, any>((state) => state.authentication);
   
    if (username && token) {
        return <Content />;
    }

    return <UnauthenticatedContent />;
}


const UpLevelApp = () => {

    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <Provider store={store}>
                <NavigationProvider>
                    <div id="zzz" className={`app ${screenSizeClass}`}>
                        <App />
                    </div>
                </NavigationProvider>
            </Provider>
        </Router>
    );
};

export default UpLevelApp;
