/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import notify from 'devextreme/ui/notify';
import { Header } from '../../components';
import RtfEditorWrapper from '../radiologistList/rtfEditor';
import ExaminationDetails from './examinationDetails';
import './results.scss';
import { AppState } from '../../reducers';
import MacrosModal from '../../components/popup-modals/macros-modal';
import { cancelExamination, editEditable, getExamDetails, saveUnsigendExam, signExamination } from '../../actions/examinationsActions';
import { doneInserting, clearMacroContent } from '../../actions/macrosActions';
import IDictationJob from '../../interfaces/examinations/IDictationJob';
import ConfirmationPopup from '../../components/popup-modals/confirmation-popup';

const ResultEditor = () => {

    
    const openedInEditor = useSelector<AppState, any>((state) => state.examinations.openedInEditor);
    const currentResult = useSelector<any, any>(((state) => state.macros.currentResult));
    const [isPopupVisible, setPopupVisible] = useState(false);
    const[canceling, setCanceling] = useState(false);

    const [inserting, insert] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();

    const saveUnsigendExamRtf = (rtfString: string) => {
        dispatch(saveUnsigendExam(openedInEditor.medicalExamId, rtfString));
    }

    const navigate = () => {
        history.push('/home');
    };

    useEffect(() => {
        if (!openedInEditor.medicalExamId) {
            notify('Nici un studiu încărcat în editor. Selectați o examinare și apăsați butonul REZULTAT.', 'warning', 3000);
            navigate();
            return;
        }
        if (openedInEditor && !openedInEditor.fetchedDetails)
            dispatch(getExamDetails(openedInEditor.medicalExamId, openedInEditor.pdfPath));

    }, []);

    const [needsSignature, sign] = useState(false);

    const signExaminationFile = async (file: File) => {
        const dictationJobObject: IDictationJob = {
            medicalExamId: openedInEditor.medicalExamId,
            signStatus: (openedInEditor.status === 'C' || openedInEditor.status === 'CE') ? 'F' : 'FF',
            jobStatus: 0,
            signUser: '',
            signDateTime: new Date(),
            resultFilePath: '',
            examReason: openedInEditor.examEditable?.examReason || '',
            dicomSequence: openedInEditor.examEditable?.dicomSequence || '',
            examTags: openedInEditor.examEditable?.examTags || '',
            signWithoutSeal: false,
            signPerOrder: !!openedInEditor.subProcedures,
        };
        dispatch(signExamination(file, dictationJobObject, openedInEditor.patientID, () => navigate()));
    };

    return (
        <div className="relict">
            <div className="">
                <>
                    <Header
                        title=""
                        menuToggleEnabled
                        toggleMenu={() => { }}
                    >
                        <Item
                            visible
                            location="before"
                            widget="dxButton"
                            cssClass="menu-button "
                        >
                            <Button icon="fas fa-file-signature" onClick={() => sign(true)} stylingMode="contained" text="Semnează" />
                        </Item>
                        <Item
                            visible
                            location="before"
                            widget="dxButton"
                            cssClass="menu-button"
                        >
                            <MacrosModal onConfirmMacro={() => { dispatch(clearMacroContent()); insert(true) }} username={''} onCancelMacro={() => dispatch(clearMacroContent())} />
                        </Item>
                        <Item
                            visible
                            location="before"
                            widget="dxButton"
                            cssClass="menu-button "
                        >
                            <Button icon="fas fa-times" onClick={() => setPopupVisible(true)} stylingMode="contained" text="Anulează" />
                        </Item>
                    </Header>
                    <ExaminationDetails editEditable={(fieldName: string, fieldValue: string) => dispatch(editEditable(fieldName, fieldValue))} currentExamination={openedInEditor} />
                </>
            </div>

            <ConfirmationPopup
                title="Deblocare examinare"
                text="Doriți să părăsiți această examinare?"
                confirmButtonText="Da"
                denyButtonText="Nu"
                showPopup={isPopupVisible}
                onConfirm={() => {setCanceling(true);  dispatch(cancelExamination(openedInEditor.medicalExamId,()=>navigate()))}}
                onDeny={() => setPopupVisible(false)}
            />
            <div className="dx-card ">
                <div className="widget-container">
                    <RtfEditorWrapper
                        currentResult={currentResult}
                        inserting={inserting}
                        onLeaveBeforeSigning={(rtfString: any) => saveUnsigendExamRtf(rtfString)}
                        doneInserting={() => dispatch(doneInserting())}
                        triggerInsertion={() => insert(false)}
                        signExamination={signExaminationFile}
                        triggerSigning={() => { sign(false); }}
                        cancelingExam={canceling}
                        isSignigng={needsSignature}
                    />
                </div>
            </div>
        </div>
    );
};

export default ResultEditor;
