const navigation = [
    {
        text: 'Examinări',
        path: '/home',
        icon: 'home',
    },
    {
        text: 'Editor',
        icon: 'fas fa-file-invoice fa-3x',
        path: '/results',
    },
];
export default navigation;
