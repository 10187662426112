import { ExaminationsActionTypes, GET_ADDITIONAL_DETAILS_SUCCESSFUL } from '../actionTypes/examinationsActionTypes';
import {
    MacrosActionTypes,
    GET_MACROS_STARTED,
    GET_MACROS_FAILED,
    GET_MACROS_SUCCESSFUL,
    GET_MACRO_CONTENT_FAILED,
    GET_MACRO_CONTENT_STARTED,
    GET_MACRO_CONTENT_SUCCESSFUL,
    SELECT_MACRO,
    DONE_INSERTING,
    CLEAR_MACRO_CONTENT,
} from '../actionTypes/macrosActionTypes';
import IMacro from '../interfaces/macros/IMacro';
import { LOGOUT_SUCCESSFUL, AuthenticationActionTypes } from '../actionTypes/authenticationActionTypes';

const initialState = {
    macrosList: [],
    gettingMacros: false,
    content: '',
    currentResult: '',
    saved: '',
};

export default function reducer(state = initialState, action: MacrosActionTypes | ExaminationsActionTypes | AuthenticationActionTypes) {
    switch (action.type) {
        case LOGOUT_SUCCESSFUL: {
            return {
                ...initialState,
            };
        }
        case GET_MACROS_STARTED:
            return {
                ...state,
            };
        case GET_MACROS_SUCCESSFUL:
            return {
                ...state,
                macrosList: action.macrosList.map((a) => ({ macroName: a } as IMacro)),
            };
        case GET_MACROS_FAILED: {
            return {
                ...state,
            };
        }
        case GET_MACRO_CONTENT_STARTED: {
            return {
                ...state,
            };
        }
        case SELECT_MACRO: {
            return {
                ...state,
                saved: action.rtfString,
            };
        }
        case GET_ADDITIONAL_DETAILS_SUCCESSFUL: {
            return {
                ...state,
                saved: action.details.base64Result,
                currentResult: action.details.base64Result,
                loading: false,
            };
        }
        case DONE_INSERTING: {
            return {
                ...state,
                currentResult: '',
            };
        }
        case GET_MACRO_CONTENT_SUCCESSFUL:
            return {
                ...state,
                content: action.content,
                macrosList: state.macrosList.map((e: IMacro) => {
                    if (e.macroName === action.macroName) return { ...e, content: action.content };
                    return e;
                }),
            };
        case GET_MACRO_CONTENT_FAILED: {
            return {
                ...state,
            };
        }
        case CLEAR_MACRO_CONTENT: {
            return {
                ...state,
                content: '',
                saved:''
            }
        }
        default:
            return state;
    }
}
