import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import { logout } from '../../actions/authenticationActions';
import ConfirmationPopup from '../popup-modals/confirmation-popup';

const UserPanel = ({ menuMode }) => {
    const { username, token, loggingIn } = useSelector((state) => state.authentication);
    const unsavedExamIds = useSelector((state) => state.examinations.unsavedExamIds);
    const [isVisible, togglePopup] = useState(false);
    const dispatch = useDispatch();

    const menuItems = [
        {
            text: 'Logout',
            icon: 'runner',
            onClick: (e) => {
                e.component.hide();
                if (unsavedExamIds && unsavedExamIds.length > 0) {
                    togglePopup(true)
                }
                else {
                    dispatch(logout());
                }
            },
        },
    ]

    const unclockedExams = ()=>{
        const formData = new FormData();
        formData.append('medicalExamIds', unsavedExamIds.join(','));
        formData.append('username', username);
        navigator.sendBeacon("/api/examinations/unlocking", formData);
    }


    return (
        <div className="user-panel">
            <ConfirmationPopup
                showPopup={isVisible}
                confirmButtonText="Acceptă"
                denyButtonText="Renunță"
                title="Examene pornite"
                text="Aveți examene blocate. Doriți să vă deconectați și să le deblocați?"
                onConfirm={() => { togglePopup(false);unclockedExams();dispatch(logout()); }}
                onDeny={() => { togglePopup(false); }}
            />
            <div className="user-info">
                <i className="fas fa-2x fa-user-circle" />
                <div className="user-name">{username}</div>
            </div>

            {menuMode === 'context' && (
                <ContextMenu
                    items={menuItems}
                    closeOnOutsideClick={true}
                    target=".user-info"
                    showEvent="dxclick"
                    width={210}
                    cssClass="user-menu"
                >
                    <Position my="top center" at="bottom center" />
                </ContextMenu>
            )}
            {menuMode === 'list' && (
                <List className="dx-toolbar-menu-action" items={menuItems} />
            )}
        </div>
    );
}

export default UserPanel;
