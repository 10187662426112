
import React from 'react';
import { connect } from 'react-redux';
import 'devexpress-richedit/dist/dx.richedit.css';
import './radiologistList.scss';
import {
    create, createOptions, RichEdit, ViewType, RichEditUnit, DocumentFormat, ParagraphLineSpacingType,
} from 'devexpress-richedit';
import { UserConfigLabels } from '../../utils/constants';

const mapStateToProps = (state: any) => ({
    procedureAssessionNr: state.examinations.openedInEditor.procedureAssessionNumber,
    medicalExamId: state.examinations.openedInEditor.medicalExamId,
    unsavedExamIds: state.examinations.unsavedExamIds,
    unsavedExamDictionary: state.examinations.unsavedExamDictionary,
    selectedMacro: state.macros.selectedMacro,
    saved: state.macros.saved,
    editorConfig: state.authentication.userConfig,
    currentResult: state.macros.currentResult,
});
class RichEditComponent extends React.Component<any, any> {
    rich = RichEdit;
    active = 0;
    hasBeenModified = false;
    signingInitiated = false;

    constructor(props: any) {
        super(props);
        this.rich = this.rich.bind(this);
    }

    componentWillUnmount() {
        console.log(this.props.cancelingExam);
        if (!this.props.cancelingExam&&!this.signingInitiated && this.hasBeenModified) {
            this.rich.prototype.exportToBase64((file) => this.props.onLeaveBeforeSigning(file), DocumentFormat.Rtf);
        }
        if (!this.rich.prototype.isDisposed) {
            this.rich.prototype.dispose();
        }
    }

    componentDidMount() {
       
        const options = createOptions();
        options.bookmarks!.visibility = true;
        options.bookmarks!.color = 'blue';
        options.fields!.updateFieldsBeforePrint = true;
        options.fields!.updateFieldsOnPaste = true;
        options.events.autoCorrect = () => { };
        options.events.activeSubDocumentChanged = () => { };
        options.events.autoCorrect = () => { };
        options.events.calculateDocumentVariable = () => { };
        options.events.characterPropertiesChanged = () => { };
        options.events.contentInserted = () => { };
        options.events.contentRemoved = () => { };
        options.events.documentChanged = () => {
            if (!this.hasBeenModified)
                this.hasBeenModified = true;
        };
        options.events.documentFormatted = () => { };
        options.events.documentLoaded = (e, s) => {
        };
        options.events.gotFocus = () => { };
        options.events.hyperlinkClick = () => { };
        options.events.keyDown = () => { };
        options.events.keyUp = () => { };
        options.events.paragraphPropertiesChanged = () => { };
        options.events.lostFocus = () => { };
        options.events.pointerDown = () => { };
        options.events.pointerUp = () => { };
        options.events.saving = () => { };
        options.events.saved = () => { };
        options.events.selectionChanged = (s) => {
            this.active = s.selection.active;
        };
        options.confirmOnLosingChanges = { enabled: false };
        options.events.customCommandExecuted = () => { };
        options.unit = RichEditUnit.Centimeter;

        const toolButtons = options.ribbon.getTab(1)!;
        options.ribbon.clearTabs();
        options.ribbon.insertTab(toolButtons);
        options.readOnly = false;
        options.view!.viewType = ViewType.Simple;
        options.view!.simpleViewSettings!.paddings = {
            left: 15,
            top: 15,
            right: 15,
            bottom: 15,
        };

        if (options.contextMenu) {
            options.contextMenu.items = [];
        }
        if (this.props.unsavedExamIds.includes(this.props.medicalExamId)) {
            const rtfString = this.props.unsavedExamDictionary[this.props.medicalExamId];
            if (rtfString.length > 0) {
                this.rich.prototype = create(document.getElementById('richEdit')!, options);
                this.rich.prototype.openDocument(rtfString, `CDRI_pro#${this.props.procedureAssessionNr}`, DocumentFormat.Rtf);
                return;
            }
        }
        if (this.props.currentResult) {
            this.rich.prototype = create(document.getElementById('richEdit')!, options);
            this.rich.prototype.openDocument(this.props.currentResult, `CDRI_pro#${this.props.procedureAssessionNr}`, DocumentFormat.Rtf);
        } else {
            this.rich.prototype = create(document.getElementById('richEdit')!, options);
            
            try {
                const { CFG_PROFILE_APP_FONT_NAME, CFG_PROFILE_APP_FONT_SIZE, CFG_PROFILE_APP_FONT_LINE_SPACE } = this.props.editorConfig;
                this.rich.prototype.document.setParagraphProperties({ start: 0, length: this.rich.prototype.document.length }, { lineSpacing: (CFG_PROFILE_APP_FONT_LINE_SPACE?parseInt(CFG_PROFILE_APP_FONT_LINE_SPACE):1), lineSpacingType: ParagraphLineSpacingType.Multiple });
                this.rich.prototype.document.setDefaultCharacterProperties({size: (CFG_PROFILE_APP_FONT_SIZE?parseInt(CFG_PROFILE_APP_FONT_SIZE):12 )});

                if (!this.rich.prototype.document.fonts.getByName(CFG_PROFILE_APP_FONT_NAME)) {
                    this.rich.prototype.document.fonts.create(CFG_PROFILE_APP_FONT_NAME, CFG_PROFILE_APP_FONT_NAME);
                }
                
                this.rich.prototype.document.setDefaultCharacterProperties({fontName: (CFG_PROFILE_APP_FONT_NAME?CFG_PROFILE_APP_FONT_NAME:"Calibri" )});
            } catch (e) {
                console.log(e);
            }
        }
        this.rich.prototype.documentName = `CDRI_pro#${this.props.procedureAssessionNr}`;
    }
     
    componentDidUpdate() {
        const { inserting, isSignigng, saved, currentResult, procedureAssessionNr,
            triggerInsertion, doneInserting, signExamination, triggerSigning } = this.props;

        if (currentResult) {
            this.rich.prototype.openDocument(currentResult, `CDRI_pro#${procedureAssessionNr}`, DocumentFormat.Rtf);
            doneInserting();
            return;
        }
        if (inserting && saved.length > 0) {
            this.rich.prototype.document.subDocuments.main.insertRtf(this.active, saved);
            triggerInsertion();
            return;
        }

        if (isSignigng) {
            this.rich.prototype.documentName = `CDRI_pro#${procedureAssessionNr}`;
            this.rich.prototype.exportToFile((file) => signExamination(file), DocumentFormat.Rtf);
            triggerSigning();
        }
    }

    render() {
        return (
            <div id="richEdit" />
        );
    }
}

export default connect(mapStateToProps, {})(RichEditComponent)
