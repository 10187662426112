import axios from 'axios';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { loadAuthenticationState } from '../utils/authenticationStateLoader';
import { rootReducer } from './index';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancers(applyMiddleware(thunk));
const x = loadAuthenticationState();

const store = createStore(rootReducer ,x as any, middleware);

if(x.authentication.token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + x.authentication.token;
}


export default store;
