import List from 'devextreme-react/list';
import React from 'react';
import { connect } from 'react-redux';
import { getMacros, getMacroContent, selectMacro } from '../../actions/macrosActions';

const mapStateToProps = (state: any) => ({
    macros: state.macros.macrosList,
    selectedMacro: state.macros.selectedMacro,
});

const mapDispatchToProps = (dispatch: Function) => ({
    getMacrosAction: () => dispatch(getMacros()),
    selectMacroAction: (macro: string) => dispatch(selectMacro(macro)),
    getMacroContents: (macroId: string) => dispatch(getMacroContent(macroId)),
});

class MacroList extends React.Component<any, any> {
    componentDidMount() {
        this.changeMacro = this.changeMacro.bind(this);
        const { macros, getMacrosAction, } = this.props;

        if (macros.length === 0) {
            getMacrosAction();
        }
    }

    shouldComponentUpdate(nextProps: any) {
        const { macros } = this.props;
        return nextProps.macros.length !== macros.length;
    }

    changeMacro = (e: any) => {
        const { getMacroContents, macros, selectMacroAction } = this.props;
        const savedMacroContent = macros.find((macro: any) => macro.mancroName === e.itemData);

        if (savedMacroContent && savedMacroContent.content) {
            selectMacroAction(savedMacroContent.content);
        }
        else {
            getMacroContents( e.itemData);
        }
    }

    render() {
        const { macros } = this.props;
        return (
            <List
                dataSource={macros.map((e: any) => e.macroName)}
                pageLoadMode="scrollBottom"
                height={500}
                noDataText="Nu există macrouri"
                selectionMode="single"
                focusStateEnabled
                activeStateEnabled
                onItemClick={this.changeMacro}
                hoverStateEnabled
                indicateLoading
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MacroList);
