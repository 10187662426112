export const SUCCESS = 'SUCCESS';
export const LOCKED = 'LOCKED';
export const PENDING = 'PENDING';
export const FORBIDDEN = 'FORBIDDEN';
export const CHANGED = 'CHANGED';

export const UserConfigLabels =
{
    CFG_PROFILE_APP_FONT_SIZE : "CFG_PROFILE_APP_FONT_SIZE",
    CFG_PROFILE_APP_FONT_NAME : "CFG_PROFILE_APP_FONT_NAME",
    CFG_PROFILE_APP_FONT_ADD_10PT_PARAGRAPH : "CFG_PROFILE_APP_FONT_ADD_10PT_PARAGRAPH",
    CFG_PROFILE_APP_FONT_LINE_SPACE : "CFG_PROFILE_APP_FONT_LINE_SPACE",
    CFG_PROFILE_APP_DELETE_BLANK_LINES : "CFG_PROFILE_APP_DELETE_BLANK_LINES",
}
