import { combineReducers } from 'redux';
import examinationsReducer from './examinationsReducer';
import macrosReducer from './macrosReducer';
import authenticationReducer from './authenticationReducer';

const appReducer = combineReducers({
    authentication: authenticationReducer,
    examinations: examinationsReducer,
    macros: macrosReducer,

});

export const rootReducer = (state?: AppState, action?: any) => appReducer(state, action);

export type AppState = ReturnType<typeof appReducer>;
