import Axios, { AxiosError } from 'axios';
import { clearRequestQueue, queueRequest, refresh } from '../actions/authenticationActions';
import authenticationActionTypes, { AuthenticationActionTypes, LOGOUT_FAILED, LOGOUT_SUCCESSFUL } from '../actionTypes/authenticationActionTypes';
import store from '../reducers/store';


export const authorizedRequestProvider = () => {

    const axiosRequestInstance = Axios.create();

    axiosRequestInstance.interceptors.response.use(
        response => response,
        (error: AxiosError) => {

            const authenticationState = store.getState().authentication;
            const status = error.response ? error.response.status : null;
            const accessTokenExpired = status === 401;

            if (status === 401 && accessTokenExpired)  {
                const originalRequestRetry = new Promise((resolve, reject) => {

                    store.dispatch(queueRequest((access_token: string | null) => {
                        var originalRequest = error.config;
                        originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
                        if (access_token) {
                            resolve(Axios.request(originalRequest));
                        } else {
                            reject(new Error(LOGOUT_SUCCESSFUL));
                        }
                    }));
                });

                if (!authenticationState.loggingIn) {
                    store.dispatch(refresh() as any)
                        .then((response: any) => {
                            if (response.type !== LOGOUT_SUCCESSFUL && response.type !== LOGOUT_FAILED) {
                                store.getState().authentication.requestQueue.map((queuedRequest: any) => {
                                        queuedRequest(response?.token);
                                });
                            } else {
                                store.getState().authentication.requestQueue.map((queuedRequest: any) => {
                                    queuedRequest(null);
                                });
                            }
                            store.dispatch(clearRequestQueue());
                        });
                }

                return originalRequestRetry;
            } else
                if (status === 401) {
                    return Promise.reject(error);
                } else {
                    return new Promise((resolve, reject) => {
                        reject(status?.toString());
                    });
                }
        });
    return axiosRequestInstance;
}

export const authorizedRequest = authorizedRequestProvider();

