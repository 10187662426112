import { withNavigationWatcher } from './contexts/navigation';
import { RadiologistListPage, ProfilePage, ResultsPage } from './pages';

const routes = [
    {
        path: '/home',
        component: RadiologistListPage,
    },
    {
        path: '/profile',
        component: ProfilePage,
    },
    {
        path: '/results',
        component: ResultsPage,
    },
];

export default routes.map((route) => ({
    ...route,
    component: (withNavigationWatcher(route.component)),
}));
