/* eslint-disable no-unused-vars */
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import Axios from 'axios';
import {
    MacrosActionTypes,
    GET_MACROS_STARTED,
    GET_MACROS_FAILED,
    GET_MACROS_SUCCESSFUL,
    GET_MACRO_CONTENT_FAILED,
    GET_MACRO_CONTENT_STARTED,
    GET_MACRO_CONTENT_SUCCESSFUL,
    SELECT_MACRO,
    DONE_INSERTING,
    CLEAR_MACRO_CONTENT,
} from '../actionTypes/macrosActionTypes';
import { AppState } from '../reducers';
import { authorizedRequest } from '../utils/authorizedRequest';

export const getMacrosStarted = (): MacrosActionTypes => ({
    type: GET_MACROS_STARTED,
});

export const getMacrosFailed = (error: Error): MacrosActionTypes => ({
    type: GET_MACROS_FAILED,
    error,
});

export const getMacrosSuccessful = (macros: string[]): MacrosActionTypes => ({
    type: GET_MACROS_SUCCESSFUL,
    macrosList: macros,
});

export function getMacros()
    : ThunkAction<Promise<any>, AppState, null, any> {
    return function (dispatch: ThunkDispatch<AppState, null, any>) {
        dispatch(getMacrosStarted());
        return authorizedRequest.get('/api/macros/')
            .then((response: any) => dispatch(getMacrosSuccessful(response.data)))
            .catch((error: Error) => dispatch(getMacrosFailed(error)));
    };
}

// ============================================================================================== //

export const getMacroContentStarted = (): MacrosActionTypes => ({
    type: GET_MACRO_CONTENT_STARTED,
});


export const getMacroContentFailed = (error: Error): MacrosActionTypes => ({
    type: GET_MACRO_CONTENT_FAILED,
    error,
});

export const doneInserting = (): MacrosActionTypes => ({
    type: DONE_INSERTING,
});

export const getMacroContentSuccessful = (content: string, macroName: string): MacrosActionTypes => ({
    type: GET_MACRO_CONTENT_SUCCESSFUL,
    content,
    macroName,
});

export const selectMacro = (rtfString: string): MacrosActionTypes => ({
    type: SELECT_MACRO,
    rtfString: rtfString,
});

export const clearMacroContent = () => ({
    type: CLEAR_MACRO_CONTENT,
})

export function getMacroContent(macroName: string)
    : ThunkAction<Promise<any>, AppState, null, any> {
    return function (dispatch: any) {
        dispatch(getMacroContentStarted());

        return authorizedRequest.get('/api/macros/content', { params: {macroName } })
            .then((response: any) => dispatch(getMacroContentSuccessful(response.data, macroName)))
            .catch((error: Error) => dispatch(getMacroContentFailed(error)));
    };
}
