import React, { useState } from 'react';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react';
import MacroList from '../lists/MacroList';
import MacroContent from '../../pages/radiologistList/readonlyEditor';
import './popup-modals.scss';

const MacroModal = (props: any) => {
    const [show, showModal] = useState(false);
    const { username } = props;

    return (
        <>
            <Button icon="fas fa-file-alt" onClick={() => showModal(true)} stylingMode="contained" text="Macro" />
            <Popup
                className="macro-modal"
                visible={show}
                onHiding={() => { showModal(false); props.onCancelMacro(); }}
                closeOnOutsideClick={false}
                showCloseButton
                showTitle
                title="Macrouri"
                container=".dx-viewport"
                width={400}
                height={200}
            >
                <div className="macro-modal-content">
                    {show
                        && 
                        (
                            <div className="dx-card macro-list">
                                <MacroList username={username} />
                            </div>
                        )}
                    <div className="dx-card macro-content">
                        <MacroContent show={show} />
                    </div>
                </div>
                <Button className="selection_btnr" stylingMode="contained" text="Acceptă" onClick={() => { showModal(false); props.onConfirmMacro(); }} />
                <Button className="selection_btnl" stylingMode="contained" text="Renunță" onClick={() => { showModal(false); props.onCancelMacro(); }} />
            </Popup>
        </>
    );
};
export default MacroModal;
