import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import {
    LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm,
} from './components';

const UnauthenticatedContent = () => (
    <Switch>
        <Route exact path="/login">
            <LoginForm />
        </Route>
        {/* <Route exact path="/create-account">
            <SingleCard description="" title="Sign Up">
                <CreateAccountForm />
            </SingleCard>
        </Route>
        <Route exact path="/reset-password">
            <SingleCard
                title="Reset Password"
                description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
            >
                <ResetPasswordForm />
            </SingleCard>
        </Route>
        <Route exact path="/change-password/:recoveryCode">
            <SingleCard description="" title="Change Password">
                <ChangePasswordForm />
            </SingleCard>
        </Route> */}
        <Redirect to="/login" />
    </Switch>
);

export default UnauthenticatedContent;
