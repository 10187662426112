export default function convertToLocale(date, locale, separator, timeIncluded) {

    let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    }

    if (timeIncluded)
        options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
        }

    if (!separator)
        separator = '/';

    try {
        const dateObj = new Date(date);
        return dateObj.toLocaleString(locale, options)
    }
    catch (exception){
        return date;
    }
}