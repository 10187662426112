/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import './profile.scss';
import { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { Header } from '../../components';

export default () => (
    <>
        <Header
            title=""
            menuToggleEnabled
            toggleMenu={() => { }}
        >
            <Item
                visible
                location="before"
                widget="dxButton"
                cssClass="menu-button "
            >
                <Button icon="fas fa-file-signature" stylingMode="contained" text="Semneaza" />
            </Item>

            <Item
                visible
                location="before"
                widget="dxButton"
                cssClass="menu-button"
            >
                <Button icon="fas fa-check" stylingMode="contained" text="Redactează" />
            </Item>

            <Item
                visible
                location="before"
                widget="dxButton"
                cssClass="menu-button"
            >
                <Button icon="fas fa-file-alt" stylingMode="contained" text="Macro" />
            </Item>
        </Header>

    </>
);
