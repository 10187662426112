/* eslint-disable react/jsx-no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { LoadIndicator } from 'devextreme-react';

const PdfCell = (props: any) => {
    const [gettingPfd, toggleLoading] = useState(false);
    const { data, getPdfResult } = props;

    const getPdf = async () => {
        toggleLoading(true);
        return getPdfResult(data.pdfPath);
    };

    return (
        <>
            {gettingPfd && <LoadIndicator id="image-indicator" indicatorSrc="https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/Loading.gif" className="short-tt" height={18} width={18} />}
            {(data.pdfPath && !gettingPfd) && <i onClick={(e) => { e.stopPropagation(); getPdf().then(() => toggleLoading(false)); e.stopPropagation(); }} className="fas fa-file-pdf fa-lg " />}
        </>
    );
};
export default React.memo(PdfCell);
