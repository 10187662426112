import { useState } from 'react';
import Cookie, { CookieSetOptions } from 'universal-cookie';

const useCookie = () => {
    const cookies = new Cookie();
    const [item, setItemValue] = useState(() => cookies.getAll());

    const setValue = (name: string, value: any, options: CookieSetOptions) => {
        setItemValue(value);
        cookies.set(name, value, options);
    };

    const removeItem = (name: string) => {
        cookies.remove(name);
    };

    const removeAll = () => {
        cookies.remove('email');
    };

    return {
        item, setValue, removeItem, removeAll,
    };
};

export default useCookie;
