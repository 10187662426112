import {
    EDIT_EXAM_FIELDS, GET_EXAMINATIONS_STARTED, GET_EXAMINATIONS_SUCCESSFUL, SET_EXAMINATION_FILTER,
    GET_ADDITIONAL_DETAILS_STARTED, GET_ADDITIONAL_DETAILS_SUCCESSFUL, GET_ADDITIONAL_DETAILS_FAILED,
    GET_EXAMINATIONS_FAILED, SIGN_EXAMINATION_SUCCESSFUL, SIGN_EXAMINATION_STARTED, SIGN_EXAMINATION_FAILED,
    CLEAR_FILTERS, OPEN_EXAM_IN_EDITOR, SELECT_EXAMINATION, SET_UNSAVED_RESULT, CANCEL_EXAMINATION_SUCCESSFUL
} from '../actionTypes/examinationsActionTypes';
import { GET_EQUIPMENT_SUCCESSFUL, GET_MEDICAL_FACILITIES_SUCCESSFUL, LOGOUT_SUCCESSFUL } from '../actionTypes/authenticationActionTypes';

interface IDictionary {
    [key: string]: string;
}


const currentDate = new Date();
currentDate.setHours(23, 59, 59);

const emptyDictionary: { [medicalExamId: string]: string } = {};
const emptyArray: string[] = [];
const intitialState = {
    data: [],
    selected: undefined,
    medicalFacilities: [],
    eqauipment: [],

    skip: 0,
    take: 100,
    totalCount: 500,
    signing: false,
    loading: false,

    openedInEditor: {
        examEditable: {
        },
    },
    unsavedExamDictionary: emptyDictionary,
    unsavedExamIds: emptyArray,
    filters: {
        medicalFacilities: [],
        equipment: [],
        includeNoResult: true,
        fieldFilter: {
            fieldName: 'Nr. Examinare',
            filterValue: '',
        },
        dateRange: {
            startDate: null,
            stopDate: currentDate

        },
    },
};

export default function reducer(state = intitialState, action: any) {
    switch (action.type) {
        case LOGOUT_SUCCESSFUL: {
            return intitialState;
        }
        
        case OPEN_EXAM_IN_EDITOR: {
            return {
                ...state,
                openedInEditor: action.examination,
            };
        }
        case GET_EQUIPMENT_SUCCESSFUL: {
            return {
                ...state,
                eqauipment: action.equipment,
            }
        }
        case GET_MEDICAL_FACILITIES_SUCCESSFUL: {
            return {
                ...state,
                medicalFacilities: action.medicalFacilities,
            }
        }
        case SELECT_EXAMINATION: {
            return {
                ...state,
                selected: action.selected,
            };
        }
        case GET_EXAMINATIONS_STARTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case SIGN_EXAMINATION_STARTED: {
            return {
                ...state,
                signing: true,
            };
        }
        case CANCEL_EXAMINATION_SUCCESSFUL:{
            const removedRtfString = {...state.unsavedExamDictionary};
            delete removedRtfString[action.medicalExamId];
        
            return {
                ...state,
                unsavedExamDictionary:removedRtfString,
                unsavedExamIds: state.unsavedExamIds.filter((e) => e !== action.medicalExamId),
                openedInEditor: {
                    key: (state.openedInEditor as any).key,
                    examEditable: {
                    },
                },
            }
        }
        case SIGN_EXAMINATION_SUCCESSFUL: {
            const removedRtfString = {...state.unsavedExamDictionary};
            delete removedRtfString[action.medicalExamId];
            
            return {
                ...state,
                signing: false,
                unsavedExamIds: state.unsavedExamIds.filter((e) => e !== action.medicalExamId),
                unsavedExamDictionary:removedRtfString,
                openedInEditor: {
                    key: (state.openedInEditor as any).key,
                    examEditable: {
                    },
                },
            };
        }
        case SIGN_EXAMINATION_FAILED: {
            return {
                ...state,
                signing: false,
                error: action.error,
            };
        }
        case GET_EXAMINATIONS_SUCCESSFUL: {
            return {
                ...state,
                loading: false,
                skip: state.skip + action.data.length,
                take: action.take,
                totalCount: action.data.length,
                data: action.data,
                selected: undefined,
                // openedInEditor: {
                //     examEditable: {

                //     },
                // },
            };
        }
        case GET_EXAMINATIONS_FAILED: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case CLEAR_FILTERS: {
            return {
                ...state,
                filters: { ...intitialState.filters },
            };
        }

        case SET_EXAMINATION_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.fieldName]: action.fieldValue,
                },
            };
        }
        case GET_ADDITIONAL_DETAILS_STARTED: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_ADDITIONAL_DETAILS_SUCCESSFUL: {
            return {
                ...state,
                loading: false,
                openedInEditor: {
                    ...state.openedInEditor,
                    ...action.details,
                    examEditable: {
                        dicomSequence: action.details.dicomSequence,
                        examTags: action.details.examTags,
                        examReason: action.details.examReason,
                    },
                },
            };
        }
        case GET_ADDITIONAL_DETAILS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }
        case EDIT_EXAM_FIELDS: {
            return {
                ...state,
                openedInEditor: {
                    ...state.openedInEditor as any,
                    examEditable: {
                        ...state.openedInEditor.examEditable,
                        [action.fieldName]: action.fieldValue,
                    },
                },
            };
        }
        case SET_UNSAVED_RESULT: {
            return {
                ...state,
                unsavedExamDictionary: { ...state.unsavedExamDictionary, [action.medicalExamId]: action.rtfString },
                unsavedExamIds: [...state.unsavedExamIds, action.medicalExamId],
            };
        }

        default:
            return state;
    }
}
