/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import './radiologistList.scss';
import {
    TreeList, ToolbarItem, Column, Scrolling, Paging, Sorting, Pager, SearchPanel, ColumnChooser,
} from 'devextreme-react/tree-list';
import { Toolbar } from 'devextreme-react';
import { Item } from 'devextreme-react/html-editor';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { Header } from '../../components';
import FiltersBand from './filtersBand';
import {
    getExaminations, getResult, selectExamiantionAction,
} from '../../actions/examinationsActions';
import PdfCell from './cellRenderer';
import { LoadPanel, TextBox, } from 'devextreme-react';

let selected = {} as any;

const RadiologistList = (props: any) => {

    const dispatch = useDispatch();
    const data = useSelector<AppState, any>((state) => state.examinations.data);
    const loading = useSelector<AppState, any>((state) => state.examinations.loading);
    const unsavedExams = useSelector<AppState, any>((state) => state.examinations.unsavedExamIds);

    useEffect(() => {
        
        if (props.selected && props.selected.medicalExamId) {
            selected = props.selected;
        }
        if (!data.length) {
            selected = {} as any;
            dispatch(selectExamiantionAction({}))
            dispatch(getExaminations(0, 400));
        }
    }, []);

    const validateBeforeLeave = () => {
        if (selected && selected.subProcedures !== null) {
            selected.subProcedures = data.find((x: any) => x.medicalExamId === selected.medicalExamId)?.subProcedures;
        }
        dispatch(selectExamiantionAction(selected));
        props.validateSelection();
    }

    return (
        <>
            <Header
                title=""
                menuToggleEnabled
                toggleMenu={() => { }}
            >
                <Item
                    visible
                    location="before"
                    widget="dxButton"
                    cssClass="menu-button "
                >
                    <Button icon="fas fa-sync-alt" onClick={() => { dispatch(selectExamiantionAction({})); selected = {} as any; dispatch(getExaminations(11, 11)); }} stylingMode="contained" text="Reîncarca" />
                </Item>
                <Item
                    visible
                    location="before"
                    widget="dxButton"
                    cssClass="menu-button"
                >
                    <Button icon="fas fa-file-invoice" stylingMode="contained" onClick={validateBeforeLeave} text="Rezultat" />
                </Item>
            </Header>
            <FiltersBand clearSelection={() => { dispatch(selectExamiantionAction({})); selected = {} as any; }} />
            <LoadPanel showPane={true} shading={true} shadingColor='rgba(0,0,0,0.4)' visible={loading} />
            <TreeList
                className="dx-card wide-card"
                noDataText="Niciun rezultat"
                elementAttr={{ id: 'gridContainer' }}
                dataSource={data}
                loadPanel={{ enabled: true }}
                columnHidingEnabled={false}
                columnAutoWidth={true}
                cellHintEnabled
                allowColumnReordering
                cacheEnabled={false}
                height="98.7%"
                activeStateEnabled
                itemsExpr="subProcedures"
                keyExpr="key"
                onEditorPreparing={(e) => {                    
                    if (e.parentType === "searchPanel") {
                        e.editorOptions.stylingMode = "outlined";
                        e.editorOptions.placeholder = "Filtru rapid..."
                        e.editorOptions.width = 300;
                    }
                }}
                onOptionChanged={() => { }}
                dataStructure="tree"
                showBorders={false}
                onRowPrepared={(e) => {
                    if (unsavedExams.includes(e.data?.medicalExamId))
                        (e as any).rowElement.className += " unsaved"
                }
                }
                onContentReady={(e) => {
                    if (!selected.medicalExamId) {
                        e.component?.clearSelection();
                        dispatch(selectExamiantionAction({}));
                    }
                }}
                onInitialized={(e) => {
                    if (selected.key) {

                        e.component?.option('selectedRowKeys', [selected.key]);
                        e.component?.navigateToRow(selected.key);
                    }
                }}
                onRowClick={(e) => {
                    if ((e.event as any).target.tagName === 'SPAN') {
                        return;
                    }
                    selected = e.data;
                }}
                selection={{ mode: 'single' }}
            >
                <Sorting mode="single" showSortIndexes />
                <SearchPanel  visible={true}></SearchPanel>
                <Scrolling mode="standard" showScrollbar rowRenderingMode="standard" columnRenderingMode="vitual" />
                <Paging enabled pageSize={50} />
                <Pager/>
                <Column
                    dataField="procedureDetail"
                    caption="Descriere Procedura"
                />
                <Column
                    dataField="date"
                    dataType='date'
                    format={'dd.MM.yyyy HH:mm'}
                    caption="Data/Timp"
                    width={190}
                />
                <Column
                    dataField="status"
                    caption="Status"
                    width={70}

                />
                <Column
                    dataField="patientFullName"
                    width={210}
                    caption="Nume Pacient"

                />
                <Column
                    dataField="patientDoB"
                    caption="Data Nasterii"
                    dataType={'date'}
                    format={'dd.MM.yyyy'}
                    width={130}
                />
                <Column
                    dataField="patientAge"
                    format={(a: any) => { return (a == 0 ? '-' : a + " Ani") }}
                    caption="Vârstă"
                    width={70}
                />
                <Column
                    dataField="patientID"
                    caption="ID"
                    width={90}
                />
                <Column
                    dataField="patientSSN"
                    caption="CNP/SSN"
                    width={150}
                />
                <Column
                    dataField="patientSex"
                    caption="Sex"
                    width={70}
                    name="Priority"
                />
                <Column
                    dataField="examAndProc"
                    caption="Examinare/Nr. proc."
                    allowSorting={false}
                    width={130}
                />
                <Column
                    dataField="refferringPhysicianName"
                    caption="Medic Solicitant"
                    width={230}

                />
                <Column
                    dataField="pdfPath"
                    caption="Rezultat"
                    allowSorting={false}
                    cellRender={(params: any) => <PdfCell getPdfResult={(a: string) => getResult(a)} data={params.data} />}
                    cssClass="result-ico"
                    width={70}
                />
                <Column
                    dataField="medicalFacility"
                    caption="Unit. medicală"
                    dataType="date"
                    width={120}
                />
                <Column
                    dataField="contract"
                    caption="Contract"
                    width={80}
                />
                <Column
                    dataField="Images"
                    caption="Imagini"
                    dataType="date"
                    width={70}
                />
                <Column
                    dataField="roomExecuted"
                    caption="Camera"
                    width={100}
                />
            </TreeList>
        </>
    );
};

export default RadiologistList;
